import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { UserDetails } from '@celum/authentication';
import { FailedEmailOperation } from '@celum/sacc/domain';

export const userActions = createActionGroup({
  source: '[User]',
  events: {
    'Log In': emptyProps(),
    'Log In Failure': props<{ authError: string }>(),
    'Finish Login': props<{ newUser?: boolean }>(),
    'Finish Login Success': props<{ user: UserDetails }>(),
    'Finish Login Failure': props<{ error: string }>(),
    'Get Details': props<{ forceReload?: boolean }>(),
    'Get Details Success': props<{ user: UserDetails }>(),
    'Get Details Failure': props<{ error: any }>(),
    'Log Out': emptyProps(),
    'Edit Profile': emptyProps(),
    'Edit Profile Success': emptyProps(),
    // prettier-ignore
    'Update': emptyProps(),
    'Update Success': props<{ user: UserDetails }>(),
    'Update Failure': props<{ error: any }>(),
    'Self Deletion': emptyProps(),
    'Self Deletion Success': emptyProps(),
    'Self Deletion Failure': props<{ error: any }>(),
    'Delete Users': props<{ emails: string[] }>(),
    'Delete Users Success': props<{ successEmails: string[]; failedDeleteOperations: FailedEmailOperation[] }>(),
    'Delete Users Failure': props<{ error: any }>(),
    'Promote Admins': props<{ emails: string[] }>(),
    'Promote Admins Success': props<{ successEmails: string[]; failedPromoteOperations: FailedEmailOperation[] }>(),
    'Promote Admins Failure': props<{ error: any }>(),
    'Promote Technical Users': props<{ emails: string[] }>(),
    'Promote Technical Users Success': props<{ successEmails: string[]; failedPromoteOperations: FailedEmailOperation[] }>(),
    'Promote Technical Users Failure': props<{ error: any }>(),
    'Update User Language': props<{ locale: string }>(),
    'Update User Language Success': props<{ user: UserDetails }>(),
    'Update User Language Failure': props<{ error: any }>(),
    'Upload Profile Picture': props<{ file: File }>(),
    'Upload Profile Picture Success': props<{ downloadLink: string }>(),
    'Upload Profile Picture Failure': props<{ error: any }>(),
    'Start Trial': emptyProps(),
    'Start Trial Success': emptyProps(),
    'Start Trial Failure': props<{ error: any }>()
  }
});
