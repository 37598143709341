<celum-dialog (onCloseAll)="onCancel()">
  <celum-dialog-content>
    <div class="sacc-delete-deactivate-account-user-dialog">
      <img class="sacc-delete-deactivate-account-user-dialog_image" [src]="dialogConfig.headlineImageUrl" alt="" />
      <h2 class="sacc-delete-deactivate-account-user-dialog_headline" [class.sacc-delete-deactivate-account-user-dialog_headline--warning]="type === 'warning'">
        {{ title }}
      </h2>

      <div class="sacc-delete-deactivate-account-user-dialog_text">
        <p class="light">{{ caption }}</p>
        <p>{{ description }}</p>
      </div>

      @if (hasDriveLicense$ | async) {
        <mat-checkbox [(ngModel)]="keepScopes">
          {{ 'COMPONENTS.ACCOUNT_USER_TABLE.DRIVE_DIALOG.CHECK_BOX' | translate }}
        </mat-checkbox>
      }
    </div>
  </celum-dialog-content>
  <celum-dialog-footer
    [color]="type === 'warning' ? ColorConstants.WARNING : ColorConstants.PRIMARY"
    [confirmBtnText]="(confirmationButtonText | uppercase) || ('COMPONENTS.DIALOG.CONFIRMATION_DIALOG.YES' | translate)"
    (onCancel)="dialogRef.close(false)"
    (onConfirm)="dialogRef.close({ keepScopes })"></celum-dialog-footer>
</celum-dialog>
