import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { TranslateService } from '@ngx-translate/core';
import imageCompression from 'browser-image-compression';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { AuthService, UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { SaccProperties } from '@celum/sacc/domain';

import { userActions } from './user.action';
import { selectUserCurrent } from './user.selectors';
import { Constants } from '../../constants';
import { ErrorFactory } from '../../error.factory';
import { HttpStatus } from '../../http.codes';
import { ApplicationInsightsService, UserResourceService as SaccUserService } from '../../services';
import { SaccBlobService } from '../../services/sacc-blob.service';
import { StorageUtils } from '../../storage.util';
import { AppState } from '../app.state';
import { invitationActions } from '../invitation/invitation.action';
import { loaderActions } from '../loader/loader.action';
import { notificationActions } from '../notification/notification.action';

export interface UserSignInFederationDomain {
  email: string;
  domainHint: string;
  signInPolicy: string;
}

@Injectable()
export class UserEffects {
  private static UNEXPECTED_ERROR_MESSAGE = 'SERVICES.GENERAL.UNEXPECTED_ERROR';

  public showLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        userActions.finishLogin,
        userActions.update,
        userActions.editProfileSuccess,
        userActions.selfDeletion,
        userActions.deleteUsers,
        userActions.uploadProfilePicture,
        userActions.startTrial
      ),
      map(() => loaderActions.show())
    )
  );

  public hideLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        userActions.finishLoginSuccess,
        userActions.finishLoginFailure,
        userActions.updateSuccess,
        userActions.updateFailure,
        userActions.selfDeletionSuccess,
        userActions.deleteUsersSuccess,
        userActions.selfDeletionFailure,
        userActions.deleteUsersFailure,
        userActions.uploadProfilePictureSuccess,
        userActions.uploadProfilePictureFailure,
        userActions.startTrialFailure,
        userActions.startTrialSuccess
      ),
      map(() => loaderActions.hide())
    )
  );

  public onAccountLoaded$ = createEffect(() =>
    this.authService.account$.pipe(
      isTruthy(),
      map(account => userActions.finishLogin({ newUser: account.newUser }))
    )
  );

  public onFinishLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.finishLogin),
      switchMap(() =>
        this.saccUserService.getUserDetails().pipe(
          tap(user => this.appInsights.setUserId(user.oid)),
          map(user => userActions.finishLoginSuccess({ user })),
          catchError(error => of(userActions.finishLoginFailure({ error })))
        )
      ),
      take(1)
    )
  );

  public finishLoginSuccessAcceptInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.finishLoginSuccess),
      take(1),
      switchMap(() =>
        this.saccUserService.getUserDetails().pipe(
          map(user => {
            // The url parameter is called invitationId, but it is actually the accountId
            const accountId = StorageUtils.getItem(Constants.INVITATION_ID_QUERY_PARAM);
            return {
              invitationId: user.invitations.find(invitation => invitation.accountId === accountId)?.id,
              accountId
            };
          }),
          filter(({ invitationId }) => !!invitationId),
          map(({ accountId, invitationId }) => invitationActions.acceptInvitation({ accountId, invitationId })),
          take(1)
        )
      )
    )
  );

  public finishLoginSuccessRequestAccountAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.finishLoginSuccess),
      take(1),
      map(() => StorageUtils.getItem(Constants.REQUEST_ACCOUNT_ACCESS_QUERY_PARAM)),
      filter(requestAccessId => !!requestAccessId),
      switchMap(requestAccessId =>
        this.saccUserService.getUserDetails().pipe(
          map(user =>
            invitationActions.requestAccountAccess({
              accountId: requestAccessId,
              repositoryId: null,
              email: user.email
            })
          ),
          take(1)
        )
      )
    )
  );

  public finishLoginSuccessRequestAccountAccessViaRepository$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.finishLoginSuccess),
      map(() => sessionStorage.getItem(Constants.CONNECT_VIA_REPO_QUERY_PARAM)),
      filter(repositoryId => !!repositoryId),
      map(repositoryId => invitationActions.requestAccountAccessViaRepository({ repositoryId }))
    )
  );

  public finishLoginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.finishLoginFailure),
        switchMap(() =>
          this.authService.signOut().pipe(
            catchError(() =>
              of(
                notificationActions.error({
                  message: this.translateService.instant(UserEffects.UNEXPECTED_ERROR_MESSAGE)
                })
              )
            )
          )
        )
      ),
    { dispatch: false }
  );

  public getDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getDetails),
      switchMap(({ forceReload }) =>
        this.saccUserService.getUserDetails(forceReload).pipe(
          map(user => userActions.getDetailsSuccess({ user })),
          catchError(error => of(userActions.getDetailsFailure({ error })))
        )
      )
    )
  );

  public getDetailsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getDetailsFailure),
      map(() =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.USER.EFFECTS.GET_USER_DETAILS_FAILURE')
        })
      )
    )
  );

  public logIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.logIn),
      tap(() => this.authService.signIn()),
      // signin should redirect to B2C. this is just here for the type check
      map(() => notificationActions.error({ message: this.translateService.instant(UserEffects.UNEXPECTED_ERROR_MESSAGE) })),
      catchError(error =>
        of(
          userActions.logInFailure({
            authError:
              error.status === HttpStatus.NOT_FOUND
                ? this.translateService.instant('SERVICES.USER.EFFECTS.USER_NOT_EXISTING')
                : this.translateService.instant('SERVICES.USER.EFFECTS.UNEXPECTED_LOGIN_ERROR')
          })
        )
      )
    )
  );

  public logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.logOut),
        switchMap(() => {
          this.appInsights.clearUserId();
          return this.authService.signOut().pipe(
            catchError(error => {
              this.store$.dispatch(
                notificationActions.error({
                  message: this.translateService.instant(UserEffects.UNEXPECTED_ERROR_MESSAGE)
                })
              );
              return of(error);
            })
          );
        })
      ),
    { dispatch: false }
  );

  // TODO what if update at server fails? Should we preventative update user on every login?
  public update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.update),
      withLatestFrom(this.store$.select(selectUserCurrent)),
      mergeMap(() =>
        this.saccUserService.updateUser().pipe(
          map(user => userActions.updateSuccess({ user })),
          catchError(error => of(userActions.updateFailure({ error })))
        )
      )
    )
  );

  public updateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateSuccess),
      map(() =>
        notificationActions.info({
          message: 'SERVICES.USER.EFFECTS.PROFILE_UPDATE_SUCCESS'
        })
      )
    )
  );

  public uploadProfilePicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.uploadProfilePicture),
      switchMap(action =>
        of(action).pipe(
          switchMap(() =>
            imageCompression(action.file, {
              maxSizeMB: SaccProperties.properties.pictureUpload.maxSize,
              maxWidthOrHeight: SaccProperties.properties.pictureUpload.maxWidthOrHeight
            })
          ),
          switchMap(compressedFile => this.storageService.uploadAvatar(compressedFile)),
          map((downloadLink: string) => userActions.uploadProfilePictureSuccess({ downloadLink })),
          catchError(error => of(userActions.uploadProfilePictureFailure({ error })))
        )
      )
    )
  );

  public reloadCurrentUserOnUploadProfilePictureSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.uploadProfilePictureSuccess),
        switchMap(() => this.userService.loadCurrentUser()),
        catchError(error => of(userActions.getDetailsFailure({ error })))
      ),
    { dispatch: false }
  );

  public showMessageOnUploadProfilePictureSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.uploadProfilePictureSuccess),
      map(() => this.translateService.instant('SERVICES.USER.EFFECTS.PROFILE_PICTURE_UPLOAD_SUCCESS')),
      map(message => notificationActions.info({ message }))
    )
  );

  public showMessageOnUploadProfilePictureFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.uploadProfilePictureFailure),
      map(() => this.translateService.instant('SERVICES.USER.EFFECTS.PROFILE_PICTURE_UPLOAD_FAILURE')),
      map(message => notificationActions.error({ message }))
    )
  );

  public selfDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.selfDeletion),
      exhaustMap(() =>
        this.saccUserService.deleteUserProfile().pipe(
          map(() => userActions.selfDeletionSuccess()),
          catchError(error => of(userActions.selfDeletionFailure({ error })))
        )
      )
    )
  );

  public selfDeletionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.selfDeletionSuccess),
      map(() => userActions.logOut())
    )
  );

  public onUserActionFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.selfDeletionFailure, userActions.startTrialFailure),
      map(action =>
        notificationActions.error({
          message: ErrorFactory.getErrorMessage(action.error, this.translateService)
        })
      )
    )
  );

  public deleteUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.deleteUsers),
      mergeMap(action =>
        this.saccUserService.deleteUsers(action.emails).pipe(
          map(deleteUsersBatchDTO =>
            userActions.deleteUsersSuccess({
              successEmails: deleteUsersBatchDTO.successful,
              failedDeleteOperations: deleteUsersBatchDTO.failed
            })
          ),
          catchError(error => of(userActions.deleteUsersFailure({ error })))
        )
      )
    )
  );

  public deleteUsersFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.deleteUsersFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.USER.EFFECTS.PROFILE_DELETION_FAILURE', {
            error: this.translateService.instant(action.error)
          })
        })
      )
    )
  );

  public deleteUsersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.deleteUsersSuccess),
      mergeMap(action => {
        let notificationAction: TypedAction<any>;

        if (action.failedDeleteOperations.length < 1) {
          notificationAction = notificationActions.info({
            message: this.translateService.instant('SERVICES.USER.EFFECTS.USER_DELETION_SUCCESS')
          });
        } else {
          // Have to join string with space separator because otherwise it will be shown as one line in snackbar
          const users = action.failedDeleteOperations
            .map(failedDeleteOperation => {
              const reason = failedDeleteOperation.reason ? ` (${this.translateService.instant(failedDeleteOperation.reason)})` : '';
              return failedDeleteOperation.email + reason;
            })
            .join(', ');
          let message = this.translateService.instant('SERVICES.USER.EFFECTS.COULD_NOT_DELETE', { users });
          message += action.successEmails.length > 0 ? this.translateService.instant('SERVICES.USER.EFFECTS.OTHERS_DELETED_SUCCESSFUL') : '';

          notificationAction = notificationActions.error({ message });
        }

        return [notificationAction];
      })
    )
  );

  public promoteAdmins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.promoteAdmins),
      mergeMap(action =>
        this.saccUserService.promoteAdmins(action.emails).pipe(
          map(promoteAdminsBatchDTO =>
            userActions.promoteAdminsSuccess({
              successEmails: promoteAdminsBatchDTO.successful,
              failedPromoteOperations: promoteAdminsBatchDTO.failed
            })
          ),
          catchError(error => of(userActions.promoteAdminsFailure({ error })))
        )
      )
    )
  );

  public promoteAdminsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.promoteAdminsFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.USER.EFFECTS.PROMOTE_ADMIN_FAILURE', {
            error: this.translateService.instant(action.error)
          })
        })
      )
    )
  );

  public promoteTechnicalUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.promoteTechnicalUsers),
      mergeMap(action =>
        this.saccUserService.promoteTechnicalUsers(action.emails).pipe(
          map(promoteTechnicalUsersBatchDTO =>
            userActions.promoteTechnicalUsersSuccess({
              successEmails: promoteTechnicalUsersBatchDTO.successful,
              failedPromoteOperations: promoteTechnicalUsersBatchDTO.failed
            })
          ),
          catchError(error => of(userActions.promoteTechnicalUsersFailure({ error })))
        )
      )
    )
  );

  public promoteTechnicalUsersFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.promoteTechnicalUsersFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.USER.EFFECTS.PROMOTE_TECHNICAL_USERS_FAILURE', {
            error: this.translateService.instant(action.error)
          })
        })
      )
    )
  );

  public promoteUsersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.promoteAdminsSuccess, userActions.promoteTechnicalUsersSuccess),
      mergeMap(action => {
        let notificationAction: TypedAction<any>;

        if (action.failedPromoteOperations.length < 1) {
          notificationAction = notificationActions.info({
            message:
              action.type === userActions.promoteAdminsSuccess.type
                ? 'SERVICES.USER.EFFECTS.USER_PROMOTION_SUCCESS'
                : 'SERVICES.USER.EFFECTS.PROMOTE_TECHNICAL_USERS_SUCCESS'
          });
        } else {
          // Have to join string with space separator because otherwise it will be shown as one line in snackbar
          const users = action.failedPromoteOperations.map(this.createFailedPromoteOperationInfo()).join(', ');
          let message = this.translateService.instant('SERVICES.USER.EFFECTS.COULD_NOT_PROMOTE', { users });
          message += action.successEmails.length > 0 ? this.translateService.instant('SERVICES.USER.EFFECTS.OTHERS_PROMOTED_SUCCESSFUL') : '';
          notificationAction = notificationActions.error({ message });
        }
        return [notificationAction];
      })
    )
  );

  public updateUserLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserLanguage),
      mergeMap(action =>
        this.saccUserService.updateUserLanguage(action.locale).pipe(
          map(user => userActions.updateUserLanguageSuccess({ user })),
          catchError(error => of(userActions.updateUserLanguageFailure({ error })))
        )
      )
    )
  );

  public updateUserLanguageFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserLanguageFailure),
      map(() =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.USER.EFFECTS.COULD_NOT_SWITCH_LANGUAGE')
        })
      )
    )
  );

  public startTrial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.startTrial),
      switchMap(() =>
        this.saccUserService.startTrial().pipe(
          map(() => userActions.startTrialSuccess()),
          catchError(err => of(userActions.startTrialFailure({ error: err })))
        )
      )
    )
  );

  public startTrialSuccess$ = createEffect(() => this.actions$.pipe(ofType(userActions.startTrialSuccess)), {
    dispatch: false
  });

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private authService: AuthService,
    private saccUserService: SaccUserService,
    private storageService: SaccBlobService,
    private translateService: TranslateService,
    private appInsights: ApplicationInsightsService,
    private userService: UserService
  ) {}

  private createFailedPromoteOperationInfo(): (failedPromoteOperation: any) => string {
    return failedPromoteOperation => {
      const reason = failedPromoteOperation.reason ? ` (${this.translateService.instant(failedPromoteOperation.reason)})` : '';
      return failedPromoteOperation.email + reason;
    };
  }
}
