<div class="sacc-account-member-status-switcher">
  <div class="sacc-account-member-status-switcher_subcontainer" [class.sacc-account-member-status-switcher_subcontainer--dual]="showSecondaryButton()">
    <span class="sacc-account-member-status-switcher_header">{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.STATUS' | translate }}</span>
    @if (!internalConfig?.isInvitation) {
      <sacc-activation-status
        class="sacc-account-member-status-switcher_item"
        data-test-account-member-status-switcher-status
        [activationStatus]="internalConfig?.userOrInvitation.status"></sacc-activation-status>
    } @else if (internalConfig.isInvitation) {
      <sacc-invitation-status
        class="sacc-account-member-status-switcher_item"
        data-test-account-member-status-switcher-status
        [invitationStatus]="internalConfig?.userOrInvitation.invitationStatus"></sacc-invitation-status>
    }
  </div>
  @if (!statusPrimarySwitched() && !statusSecondarySwitched()) {
    <div
      class="sacc-account-member-status-switcher_subcontainer"
      [class.sacc-account-member-status-switcher_subcontainer--dual]="showSecondaryButton()"
      [class.sacc-account-member-status-switcher_subcontainer-buttons]="showSecondaryButton()">
      <span class="sacc-account-member-status-switcher_header">{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.ACTIONS' | translate }}</span>
      <div class="sacc-account-member-status-switcher_action-buttons">
        <sacc-icon-text-button
          class="sacc-account-member-status-switcher_action-button"
          data-test-account-member-status-switcher-status-button-primary
          [disabled]="internalConfig?.disabled"
          [icon]="internalConfig?.buttonPrimaryIcon"
          [label]="internalConfig?.buttonPrimaryLabel"
          [matTooltip]="internalConfig?.buttonPrimaryTooltip | translate"
          [size]="'medium'"
          (clicked)="onStatusSwitchPrimaryClicked()"></sacc-icon-text-button>
        @if (showSecondaryButton()) {
          <sacc-icon-text-button
            class="sacc-account-member-status-switcher_action-button"
            data-test-account-member-status-switcher-status-button-secondary
            [disabled]="internalConfig?.disabled"
            [icon]="internalConfig?.buttonSecondaryIcon"
            [label]="internalConfig?.buttonSecondaryLabel"
            [matTooltip]="internalConfig?.buttonSecondaryTooltip | translate"
            [size]="'medium'"
            (clicked)="onStatusSwitchSecondaryClicked()"></sacc-icon-text-button>
        }
      </div>
    </div>
  } @else {
    <div
      class="sacc-account-member-status-switcher_subcontainer sacc-account-member-status-switcher_subcontainer-activate"
      [class.sacc-account-member-status-switcher_subcontainer--dual]="showSecondaryButton()"
      [class.sacc-account-member-status-switcher_subcontainer--dual-activate]="showSecondaryButton()">
      <div
        class="sacc-account-member-status-switcher_activate-container"
        data-test-account-member-status-switcher-status-preview-activate
        [class.sacc-account-member-status-switcher_activate-container--dual]="showSecondaryButton()">
        <span class="sacc-account-member-status-switcher_text-bold sacc-account-member-status-switcher_activate-header">
          {{ (statusPrimarySwitched() ? internalConfig?.switchedPrimaryTextHeader : internalConfig?.switchedSecondaryTextHeader) | translate }}
        </span>
        <span class="sacc-account-member-status-switcher_text sacc-account-member-status-switcher_activate-text">
          {{ (statusPrimarySwitched() ? internalConfig?.switchedPrimaryText : internalConfig?.switchedSecondaryText) | translate }}
        </span>
      </div>
    </div>
  }
</div>
