import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, map, tap, withLatestFrom } from 'rxjs/operators';

import { AccountAccess, AuthService, InvitationStatus, UserDetails, UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { AccountMember } from '@celum/sacc/domain';
import {
  AppState,
  MemberShipTableColumns,
  selectAccountActiveAccountId,
  selectInvitationFilteredCount,
  selectInvitationsLoading,
  selectInvitationUserInvitationFilter,
  selectInvitationUserInvitationsAll,
  selectInvitationUserInvitationsAllLoaded,
  selectUserCanEditActiveAccount
} from '@celum/sacc/shared';

export type InvitationTableState = {
  allUserInvitationsLoaded: boolean;
  userInvitationsLoading: boolean;
  availableColumns: MemberShipTableColumns[];
  token: string;
  tokenExpiration: number;
};

export type InvitationTableViewModel = {
  searchString: string;
  invitations: ExtendedSaccAccountUser[];
  invitationCount: number;
  canEdit: boolean;
  displayedColumns: MemberShipTableColumns[];
  currentUser: UserDetails;
  accountAccess: AccountAccess;
} & Omit<InvitationTableState, 'tokenExpiration'>;

export type ExtendedSaccAccountUser = AccountMember & {
  canBeDeleted: boolean;
  canBeRejected: boolean;
  canBeAccepted: boolean;
  canBeResent: boolean;
};

@Injectable()
export class InvitationTableService extends ComponentStore<InvitationTableState> {
  public vm$ = this.createViewModel();

  constructor(
    private store$: Store<AppState>,
    private userService: UserService,
    private authService: AuthService
  ) {
    super({
      availableColumns: [],
      allUserInvitationsLoaded: undefined,
      userInvitationsLoading: undefined,
      token: undefined,
      tokenExpiration: undefined
    });

    // Select and store values in the ComponentStore that the component (not the template) needs access to
    combineLatest([this.store$.select(selectInvitationUserInvitationsAllLoaded), this.store$.select(selectInvitationsLoading)])
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(this.select(state => state.token)),
        switchMap(([[allUserInvitationsLoaded, userInvitationsLoading], token]) => {
          if (!token || this.get().tokenExpiration === undefined || Date.now() > this.get().tokenExpiration) {
            return this.authService.getAuthResult().pipe(
              tap(result =>
                this.patchState({
                  tokenExpiration: result.expiresOn.getTime(),
                  token: result.idToken
                })
              ),
              map(() => ({
                allUserInvitationsLoaded,
                userInvitationsLoading
              }))
            );
          }

          return of({
            allUserInvitationsLoaded,
            userInvitationsLoading
          });
        }),
        takeUntilDestroyed()
      )
      .subscribe(data =>
        this.patchState({
          allUserInvitationsLoaded: data.allUserInvitationsLoaded,
          userInvitationsLoading: data.userInvitationsLoading
        })
      );
  }

  public get allUserInvitationsLoaded(): boolean {
    return this.get().allUserInvitationsLoaded;
  }

  public get userInvitationsLoading(): boolean {
    return this.get().userInvitationsLoading;
  }

  public init(availableColumns: MemberShipTableColumns[]): void {
    this.patchState({ availableColumns });
  }

  private createViewModel(): Observable<InvitationTableViewModel> {
    const invitations$ = this.store$.select(selectInvitationUserInvitationsAll);
    const filteredInvitationCount$ = this.store$.select(selectInvitationFilteredCount);
    const canEdit$ = this.store$.select(selectUserCanEditActiveAccount);
    const accountId$ = this.store$.select(selectAccountActiveAccountId);
    const currentUser$ = this.userService.currentUser$.pipe(isTruthy());
    const searchString$ = this.store$.select(selectInvitationUserInvitationFilter);
    return this.select(
      this.state$,
      invitations$,
      filteredInvitationCount$,
      canEdit$,
      currentUser$,
      accountId$,
      searchString$,
      (state, invitations, invitationCount, canEdit, currentUser, accountId, searchString) => ({
        ...state,
        invitations: invitations.map(invitation => ({
          ...invitation,
          canBeDeleted: [InvitationStatus.PENDING_APPROVAL, InvitationStatus.INVITED, InvitationStatus.DISAPPROVED, InvitationStatus.REJECTED].includes(
            invitation.invitationStatus
          ),
          canBeRejected: invitation.invitationStatus === InvitationStatus.PENDING_APPROVAL,
          canBeAccepted: invitation.invitationStatus !== InvitationStatus.INVITED && invitation.invitationStatus !== InvitationStatus.REJECTED,
          canBeResent: [InvitationStatus.INVITED, InvitationStatus.REJECTED].includes(invitation.invitationStatus),
          canBeModified: [InvitationStatus.INVITED, InvitationStatus.PENDING_APPROVAL].includes(invitation.invitationStatus)
        })),
        invitationCount,
        allInvitationsLoaded: state.allUserInvitationsLoaded,
        displayedColumns: state.availableColumns,
        canEdit,
        accountAccess: currentUser.accountAccesses.find(access => access.accountId === accountId),
        currentUser,
        searchString
      })
    );
  }
}
